.App {
  text-align: center;
  max-width: 800px;
  margin: auto;
  padding: 2em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-window>ul:nth-child(1) {
  justify-content: center;
}

.reduced-window {
  max-width: 800px !important;
  margin: 0 auto;
}

.valutazione-box {
  padding: 1em;
  margin-top: 1em;
  border-radius: 10px;
  text-align: center;
}

.valutazione-codici {
  margin: 0;
}

.valutazione-codici>p {
  margin: 0;
}

.valutazione-bounce {
  margin: 1em;
}

.estero-info {
  margin: 1em;
  padding: 0;
}

.estero-info>p {
  margin: 0;
}

.codes-box {
  padding: .5em;
  background-color: #0000000f;
  border-radius: 5px;
  border: 1px dashed #0003;
}

.valutazione-header,
.modify-evaluation-box .card-body,
.modify-evaluation-box .card-header {
  align-self: center;
}

.valutazione-header p.rebounce {
  background-color: #0001;
  padding: 1em;
  border-radius: 1em;
}

.valutazione-header .valutazione-values {
  background-color: #C7AEEFA1;
  padding: 1em;
  border-radius: 1em;
}

.valutazione-header .card-body,
.modify-evaluation-box .card-body {
  font-size: 2em;
  font-weight: bold;
}

.lacompriamonoi-box {
  background-color: #F7F3E6;
  margin-top: 1em;
  padding: 1em;
  border-radius: 1em;
}

.valutazione-header .card,
.modify-evaluation-box .card {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: 5px 5px;
}

.valutazione-header .card-header img,
.modify-evaluation-box .card-header img {
  width: 100%;
}

.valutazione-box .affidabilita {
  margin-top: 1em;
  border: 1px dashed gray;
}

.valutazione-header .card-header,
.modify-evaluation-box .card-header {
  background-color: #ffffffa2;
}

.journal-container,
.journal-container>*>ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.journal-container .journal-title {
  font-weight: bold;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, .1);
  display: block;
}

.journal-container .journal-entries {
  text-align: left;
  padding: 1em 0;
  font-family: 'Courier New', Courier, monospace;
}

.journal-container .journal-entries li {
  padding: 3px 0;
}

.journal-container .journal-entries>li::first-letter {
  text-transform: capitalize;
}

.seller-infos {
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 20);
  margin-top: .5em;
}

.listing-nav {
  margin: 1em;
}

.report-row .operator {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-row:hover {
  background-color: #e9e9e9;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.carzooon-logo {
  height: 30px;
}

.nav-tabs .nav-link {
  background-color: rgba(0, 0, 0, .1);
  border-radius: 0;
}

.vehicle-descriptor {
  background-color: rgba(0, 0, 0, .1);
  border: 1px dashed rgba(0, 0, 0, 20);
  border-radius: 3px;
}

.vehicle-descriptor .registration-state {
  margin-left: 8px;
  background-color: rgba(0, 0, 0, .1);
  padding: 0 8px;
  border-radius: 8px;
}

.vehicle-descriptor .vehicle-header {
  padding: 3px;
  background-color: rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

strong.warning {
  border-bottom: 1px dashed black;
}

.post-price-form {
  background-color: rgba(0, 0, 0, .1);
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, .3);
  text-align: left
}


.evaluation-state .state {
  padding: 3px 8px;
  border-radius: 8px;
  display: block;
  text-align: center;
}

.evaluation-state {
  min-width: 125px;
}

.evaluation-state .state.waiting {
  background-color: yellow;
}

.evaluation-state .state.confirmed {
  background-color: #1bc520;
}

.evaluation-state .state.rejected {
  background-color: #ff5954;
}

.modify-evaluation-box {
  background-color: #00000010;
  padding: 1em;
  border-radius: 8px;
}

.single-image-uploader {
  background-size: cover;
  opacity: .4;
}

.single-image-uploader:hover {
  opacity: 1 !important;
}

.evaluation-deny.card {
  color: var(--bs-light);
  background-color: var(--bs-danger);
}

.evaluation-approved.card {
  color: var(--bs-light);
  background-color: var(--bs-success);
}

.evaluation-queue.card {
  background-color: var(--bs-warning);
  color: var(--bs-dark);
}

.photosuploader-caption {
  font-size: .85rem;
  font-style: italic;
}

.custom-progress-bg {
  background-color: #d7d7d7;
  border: 1px solid #c1c1c1;
}

.previous-evaluations {
  margin: 24px 0;
}

.previous-evaluations table {
  max-width: 100%;
  display: block;
  overflow: scroll;
  max-height: 513px;
}

.previous-evaluations .card-header {
  font-size: .8rem;
}

.previous-evaluations .card {
  height: 100%;
}

.previous-evaluations .card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
}